<template>
  <div class="disk-sidebar-item">
    <div class="item">
      <div
        class="row-name"
        :class="{ active: sideBarData.id == $store.state.sidebarActive }"
        @click="handleItem(sideBarData.id, sideBarData.name)"
      >
        <i
          class="arrow-icon"
          v-if="hasChildren"
          :class="{ 'arrow-icon-rotate': !open }"
          @click.stop="handleArrow(sideBarData.id)"
        ></i>
        <img
          :src="[
            sideBarData.id == $store.state.sidebarActive
              ? sideBarData.hIcon
              : sideBarData.nIcon,
          ]"
          alt=""
          width="14"
          height="14"
          v-else-if="sideBarData.nIcon"
        />
        {{ sideBarData.name }}
      </div>
      <div class="submenu" v-show="open" v-if="hasChildren">
        <disk-side-bar-item
          :sideBarData="item"
          v-for="item in sideBarData.children"
          :key="item.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getRecycleBinList } from 'api/disk'
export default {
  inject: ['disk'],
  name: 'DiskSideBarItem',
  props: ['sideBarData'],
  data() {
    return {
      selectIndex: '1',
      open: true,
    }
  },
  computed: {
    hasChildren: function () {
      return this.sideBarData.children && this.sideBarData.children.length
    },
  },
  created() {
    this.$store.commit('ChangeSideBarId', 0)
  },
  methods: {
    handleArrow() {
      if (this.hasChildren) {
        this.open = !this.open
      }
    },
    handleItem(id, value) {
      if (value == '回收站') {
        this.disk.showDale = true
      } else {
        this.disk.showDale = false
      }
      if (
        value == '存证' ||
        value == '授权' ||
        value == '溯源报告' ||
        value == '云文档' ||
        id == 0
      ) {
        this.disk.MyItem = [{ id: '0', name: '我的网盘' }]
        this.disk.nubs1 = 1
      } else {
        this.disk.nubs1 = 2
        this.disk.MyItem = [{ id: '0', name: '' }]
      }

      if (id == 0) {
        this.disk.nubs = 2
        this.disk.getDiskLists({
          id: 0,
          pageIndex: 1,
          pageSize: 11,
          timeSort: 'desc',
        })
      }

      // } else if (id == 10) {
      //   this.getRecyCleList();
      //   this.disk.sideBarId = id;
      // } else {
      else {
        this.disk.getSideBarType({
          name: value,
          pageIndex: 1,
          pageSize: 11,
        })

        // this.disk.handleSearchForType(id);
      }
      this.$store.commit('ChangeSideBarId', id)
    },
    async getRecyCleList() {
      const data = {
        pageIndex: 1,
      }
      const res = await getRecycleBinList(data)
      if (res.code === 200) {
        res.data.forEach((item) => {
          switch (item.filetype) {
            case 0:
              if (item.isDeleted == -1) {
                item.nub = '01'
              } else {
                item.nub = '03'
              }
              break
            case 1:
              item.nub = '04'
              break
            case 2:
              item.nub = '07'
              break
            case 3:
              item.nub = '08'
              break
            case 4:
              item.nub = '05'
              break
            case 5:
              item.nub = '06'
              break
            case 6:
              item.nub = '07'
              break
            case 7:
              item.nub = '04'
              break
            case 8:
              item.nub = '05'
              break
            case 9:
              item.nub = '14'
              break
            case 10:
              item.nub = '14'
              break
            case 11:
              item.nub = '09'
              break
            case 12:
              item.nub = '09'
              break
            case 13:
              item.nub = '09'
              break
            case 14:
              item.nub = '10'
              break
            case 15:
              item.nub = '01'
              break
            case 16:
              item.nub = '09'
              break
            case 17:
              item.nub = '12'
              break
            case 18:
              item.nub = '12'
              break
            case 19:
              item.nub = '13'
              break
            case 20:
              item.nub = '11'
              break
            case 21:
              item.nub = '15'
              break
            case 22:
              item.nub = '11'
              break
            case 23:
              item.nub = '15'
              break
          }
        })
        this.disk.fileList = res.data
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.disk-sidebar-item {
  width: 100%;
  .row-name {
    position: relative;
    @include flex-start;
    padding-left: 30px;
    height: 40px;
    width: inherit;
    line-height: 40px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    .arrow-icon {
      cursor: pointer;
      position: absolute;
      left: 15px;
      top: 50%;
      display: inline-block;
      width: 8px;
      height: 5px;
      @include backgroundGroup('~assets/images/disk/arrow-icon.png');
      transform: translateY(-50%);
    }
    &:hover {
      background: #ebf2ff;
    }
    // .arrow-icon-rotate {
    //   transform: rotate(180deg);
    // }
    img {
      margin-right: 8px;
    }
  }
  .active {
    background: #ebf2ff;
    color: $color-text-active;
  }
}
</style>
