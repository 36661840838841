<template>
  <div class="disk-search">
    <div class="disk-search-input">
      <input
        @keydown.enter="handleSearch"
        type="text"
        v-model="value"
        placeholder="搜索我的网盘文件"
      />
    </div>
    <div class="disk-search-btn" @click="handleSearch"></div>
  </div>
</template>

<script>
export default {
  name: "DiskSearch",
  data() {
    return {
      value: ""
    };
  },
  methods: {
    handleSearch() {
      this.$emit("handleSearch", this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.disk-search {
  @include flex-between;
  padding: 0 20px;
  width: 250px;
  height: 28px;
  border-left: 1px solid #eceff1;
  box-sizing: border-box;
  .disk-search-input {
    flex: 1;
    input {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      &::placeholder {
        font-size: $font-size-medium;
        font-weight: 400;
        color: $color-text-gray-s;
      }
    }
  }
  .disk-search-btn {
    cursor: pointer;
    display: inline-block;
    width: 15px;
    height: 15px;
    @include backgroundGroup("~assets/images/disk/disk-search-btn.png");
  }
}
</style>
