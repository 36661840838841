<template>
  <div class="disk-side-bar">
    <!-- logo -->
    <div class="title" @click="evidence"><i class="disk-logo"></i>智能网盘</div>
    <!-- 侧边栏 -->
    <div class="side-bar">
      <disk-side-bar-item
        :sideBarData="item"
        v-for="item in sideBarData"
        :key="item.id"
      />
    </div>
    <!-- 存储空间 -->
    <div class="storage-space">
      <div class="progress-bar__outer">
        <div
          class="progress-inner"
          ref="progressinner"
          style="width: 50%"
        ></div>
      </div>
      <div class="storage-detail">
        <div class="storage-proportion" ref="messageInfoTableRef">
          {{ nub }}
        </div>
        <!-- <div class="addstorage" @click="addstorage" v-show="dilatation">
          扩容
        </div>
        <div class="addstorage1" @click="addstorage" v-show="!dilatation">
          <span>扩容</span>
          <img src="../../assets/images/disk/Icons/17.png" alt="" />
        </div> -->
      </div>
      <div class="fooder-bd">
        <el-switch
          @change="switchValue"
          v-model="value"
          active-color="#4587ff"
          inactive-color="#bcbcbc"
        >
        </el-switch>
        <span>智能解析</span>
      </div>
    </div>
  </div>
</template>

<script>
import DiskSideBarItem from "./DiskSideBarItem.vue";
import { sidebarItem } from "@/config/sidebar";
import { memoryFile, parse } from "api/disk";
export default {
  components: { DiskSideBarItem },
  name: "DiskSideBar",
  data() {
    return {
      value: false,
      nub: "",
      sideBarData: sidebarItem,
      dilatation: true
    };
  },
  created() {},

  mounted() {
    this.getMemoryFile();
    // setTimeout(() => {
    //   if (this.$refs.messageInfoTableRef.offsetWidth > 80) {
    //     this.dilatation = false;
    //   } else {
    //     this.dilatation = true;
    //   }
    //   console.log(this.$refs.messageInfoTableRef.offsetWidth);
    // }, 100);
  },
  methods: {
    switchValue(val) {
      let id = {};
      if (val) {
        this.$message({
          type: "success",
          message: "智能解析已开启,智能网盘可作为推荐数据源!"
        });
        id = {
          id: 1
        };
      } else {
        this.$message({
          type: "info ",
          message: "智能解析已关闭,智能网盘不能作为推荐数据源!"
        });
        id = {
          id: 0
        };
      }
      parse(id);
    },
    getMemoryFile() {
      memoryFile().then((res) => {
        if (res.data.isParse == 1) {
          this.value = true;
        } else {
          this.value = false;
        }
        this.nub = res.data.usedSpace + " / " + res.data.totalSpace;
        let widths = this.$refs.messageInfoTableRef.offsetWidth;
        setTimeout(() => {
          if (widths > 94) {
            this.dilatation = false;
          } else {
            this.dilatation = true;
          }
        }, 100);
        this.$refs.progressinner.style.width = res.data.rario + "%";
      });
    },
    evidence() {
      this.$parent.nubs = 2;
      this.$store.commit("ChangeSideBarId", 0);
      (this.$parent.MyItem = [{ id: "0", name: "我的网盘" }]),
        this.$parent.getDiskLists({
          id: 0,
          pageIndex: 1,
          pageSize: 11,
          timeSort: "desc"
        });
    },
    addstorage() {
      this.$parent.dialogVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.disk-side-bar {
  width: 148px;
  height: 100%;
  border-right: 1px solid #eceff1;
  .title {
    cursor: pointer;
    @include flex-center;
    width: 148px;
    height: 52px;
    text-align: center;
    font-size: $font-size-medium;
    font-weight: bold;
    color: $color-text-black;
    border-bottom: 1px solid #eceff1;
    .disk-logo {
      margin-right: 5px;
      display: inline-block;
      width: 14px;
      height: 14px;
      @include backgroundGroup("~assets/images/disk/disklogo.png");
    }
  }
  .side-bar {
    height: calc(100% - 100px - 50px);
  }
  .storage-space {
    @include flex-center;
    flex-direction: column;
    height: 100px;
    .progress-bar__outer {
      position: relative;
      width: 127px;
      height: 6px;
      background: #eeeff4;
      border-radius: 3px;
      .progress-inner {
        height: inherit;
        border-radius: 3px;
        background: #4587ff;
      }
    }
    .storage-detail {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: $font-size-small;
      .storage-proportion {
        margin-top: 5px;
        font-size: $font-size-small;
        font-weight: bold;
        color: #a5a8b1;
      }
      .addstorage {
        margin-top: 5px;
        cursor: pointer;
        margin-left: 25px;
        font-weight: bold;
        color: $color-text-active;
      }
      .addstorage1 {
        justify-content: left;
        width: 100%;
        margin-top: 9px;
        cursor: pointer;
        margin-left: 13px;
        font-size: 12px;
        font-weight: bold;
        color: $color-text-active;
        img {
          width: 6px;
          height: 11px;
          float: right;
          margin-right: 12px;
        }
      }
    }
  }
  .fooder-bd {
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    margin: 15px 0;
    display: flex;
    justify-content: left;

    span {
      margin-left: 30px;
      font-size: 14px;
      color: #999;
    }
  }
}
</style>
<style>
.el-message {
  min-width: 100px !important;
}
</style>
