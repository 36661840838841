var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"disk-content-header"},[_c('div',{staticClass:"file-operate"},[(_vm.uploadFiles)?_c('div',{staticClass:"upload-btn",on:{"click":function($event){return _vm.$emit('uploadFiles')}}},[_c('i',{staticClass:"upload-btn-icon"}),_vm._v(" 上传 ")]):_vm._e(),(_vm.addFiles)?_c('div',{staticClass:"create-dir",on:{"click":function($event){return _vm.$emit('addFiles')}}},[_c('i',{staticClass:"create-dir-icon"}),_vm._v(" 新建文件夹 ")]):_vm._e(),(_vm.addFiles1 && this.$store.state.sidebarActive != 10)?_c('div',{staticClass:"create-dir",on:{"click":_vm.shareFlie}},[_c('i',{staticClass:"share-dir-icon"}),_vm._v(" 分享 ")]):_vm._e(),(_vm.addFiles1 && this.$store.state.sidebarActive != 10)?_c('div',{staticClass:"create-dir",on:{"click":_vm.downloadFied}},[_c('i',{staticClass:"Download-dir-icon"}),_vm._v(" 下载 ")]):_vm._e(),(
        _vm.addFiles1 &&
        this.$store.state.sidebarActive != 10 &&
        this.$parent.siderNum.name != '最近'
      )?_c('div',{staticClass:"create-dir",on:{"click":_vm.deletsFiles}},[_c('i',{staticClass:"delete-dir-icon"}),_vm._v(" 删除 ")]):_vm._e(),(
        _vm.addFiles1 &&
        this.$store.state.sidebarActive != 10 &&
        this.$parent.siderNum.name != '最近'
      )?_c('div',{staticClass:"create-dir",on:{"click":_vm.moveTo}},[_c('i',{staticClass:"move-dir-icon"}),_vm._v(" 移动 ")]):_vm._e(),(_vm.addFiles2 && this.$store.state.sidebarActive == 10)?_c('div',{staticClass:"create-dir1 create-dir",on:{"click":_vm.removeFile}},[_vm._v(" 清空所有文件 ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }