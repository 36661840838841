<template>
  <div class="disk-path">
    <div class="disk-path2" ref="diskPath2">
      <div
        class="disk-path1"
        @click="diskPath(index, item.id)"
        v-for="(item, index) in dataItem"
        :key="item.id"
      >
        {{ item.name }}

        <img
          v-if="item.name"
          style="margin: 0 10px"
          :src="require('assets/images/disk/Icons/16.png')"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DiskPath",
  props: {
    dataItem: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      diskPath2: 0
    };
  },
  mounted() {
    this.getdiskpath();
  },
  methods: {
    getdiskpath() {
      this.diskPath2 = this.$refs.diskPath2.offsetWidth;
    },
    diskPath(index, value) {
      let str = this.$parent.MyItem;
      let str1 = this.$parent.MyItem1;
      let str3 = str.splice(index + 1, str.length - 1);
      let str2 = [];
      if (str1.length - str3.length > 0) {
        str2 = str1.slice(str1.length - str3.length, str1.length);
      } else {
        str2 = str1;
      }
      str2.reverse();
      str2.forEach((item1) => {
        str.unshift(item1);
      });
      this.$parent.getDiskLists({
        id: value,
        pageIndex: 1,
        pageSize: 11,
        timeSort: "desc"
      });
      // let str = this.$parent.MyItem;
      // str.forEach((item) => {
      //   if (value == item.id) {
      //     str.splice(index + 1, str.length - 1);
      //     this.$parent.getDiskLists({
      //       id: item.id,
      //       pageIndex: 1,
      //
      //       ,
      //     });
      //   }
      // });
      str1.splice(str1.length - str3.length, str.length - 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.disk-path {
  font-size: $font-size-medium;
  font-weight: 500;
  color: $color-text-active;
  cursor: pointer;
}
.disk-path1 {
  display: flex;
  vertical-align: middle;
  align-items: center;
  img {
    margin: 0 20px;
  }
}
.disk-path2 {
  display: flex;
}
</style>
