export const sidebarItem = [
  {
    name: "我的文件",
    id: 0,
    children: [
      {
        nIcon: require("assets/images/disk/lately-n.png"),
        hIcon: require("assets/images/disk/lately-h.png"),
        name: "最近",
        id: 5
      },
      {
        nIcon: require("assets/images/disk/cz-n.png"),
        hIcon: require("assets/images/disk/cz-h.png"),
        name: "存证",
        id: 6
      },
      {
        nIcon: require("assets/images/disk/auth-n.png"),
        hIcon: require("assets/images/disk/auth-h.png"),
        name: "授权",
        id: 7
      },
      {
        nIcon: require("assets/images/disk/sybg-n.png"),
        hIcon: require("assets/images/disk/sybg-h.png"),
        name: "溯源报告",
        id: 8
      },
      {
        nIcon: require("assets/images/disk/doc-n.png"),
        hIcon: require("assets/images/disk/doc-h.png"),
        name: "文档",
        id: 1
      },
      {
        nIcon: require("assets/images/disk/img-n.png"),
        hIcon: require("assets/images/disk/img-h.png"),
        name: "图片",
        id: 2
      },
      {
        nIcon: require("assets/images/disk/video-n.png"),
        hIcon: require("assets/images/disk/video-h.png"),
        name: "视频",
        id: 3
      },
      {
        nIcon: require("assets/images/disk/other-n.png"),
        hIcon: require("assets/images/disk/other-h.png"),
        name: "其他",
        id: 4
      },
      {
        nIcon: require("../assets/images/disk/Icons/18.png"),
        hIcon: require("../assets/images/disk/Icons/18.png"),
        name: "云文档",
        id: 98
      }
      // {
      //   nIcon: require("assets/images/disk/lock-n.png"),
      //   hIcon: require("assets/images/disk/lock-h.png"),
      //   name: "隐藏空间",
      //   id: 99,
      // },
    ]
  },

  {
    name: "回收站",
    id: 10
  }
];
