<template>
  <div class="disk">
    <disk-sidebar />

    <div class="disk-content">
      <!-- 头部 -->
      <disk-header
        ref="uploadFiles"
        @uploadFiles="uploadFiles"
        @addFiles="addFiles"
      />
      <div class="dik-nav">
        <!--刷新 -->
        <div class="disk-operate">
          <div class="disk-operate-prev" @click="Backoff"></div>
          <div class="disk-operate-refresh" @click="refresh">
            <!-- @dblclick="refreshs" -->
          </div>
        </div>
        <!-- 路径 -->
        <disk-path :dataItem="MyItem" ref="diskPath" />
        <!-- 搜索 -->
        <disk-search @handleSearch="handleSearch" ref="valueSearch" />
        <div class="show-type" @click="showType"></div>
      </div>
      <div v-if="showDale" class="showDale">提示:回收站内容10天后自动删除</div>
      <disk-table-row :listData="fileList" ref="diskTable" />
    </div>
    <!--上传附件弹出框 -->
    <el-dialog
      v-dialogDrag
      title="文件上传"
      center
      :visible.sync="uploadVisible"
      @close="handlerClose"
      class="upload"
    >
      <UploadBigFile
        class="uploadSlot"
        v-if="uploadVisible"
        :parentId="parentId"
        :columnType="columnType"
        :fileType="fileType"
      ></UploadBigFile>
    </el-dialog>

    <!-- 新增文件夹 -->
    <el-dialog
      :title="addtitle"
      :visible.sync="addFilesPopup"
      class="addFiles"
      @close="closeAddpopup"
    >
      <!-- <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="名称：">
          <el-input
            v-model="form.name"
            @keyup.enter.native="handleSure"
        
          ></el-input>
        </el-form-item>
      </el-form> -->
      <span>名称：</span>
      <el-input v-model="form.name" @keyup.enter.native="handleSure"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSure">确 定</el-button>
        <el-button @click="addFilesPopup = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- vip权益 -->
    <el-dialog :visible.sync="dialogVisible" class="interest">
      <div class="content">
        <div class="content-item" v-for="(item, index) in vipList" :key="index">
          <div class="content-item-title">
            <div class="content-item-line"></div>
            {{ item.title }}
          </div>
          <div class="content-desc">
            <div class="desc-item">
              <div class="desc-item-content">
                <i class="desc-item-content-icon drillIcon"></i>
                <div class="desc-item-content-title">{{ item.size }}</div>
                <div class="desc-item-content-story">{{ item.sizeDesc }}</div>
              </div>
            </div>
            <div class="desc-item">
              <div class="desc-item-content">
                <i class="desc-item-content-icon textIcon"></i>
                <div class="desc-item-content-title">{{ item.amount }}</div>
                <div class="desc-item-content-story">{{ item.amountDesc }}</div>
              </div>
            </div>

            <div class="desc-bigBox">
              <div class="desc-bigBox-text">
                <div class="desc-bigBox-title">
                  年卡：<span>{{ item.yearCard }}￥</span>
                </div>
                <div class="desc-bigBox-story">{{ item.yearCardDesc }}</div>
              </div>
              <div class="desc-bigBox-btn" @click="descBigBoxbtn">
                {{ item.classify == 1 ? '开通VIP' : '开通SVIP' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 链接二维码弹窗 -->
    <el-dialog
      title="二维码"
      :close-on-click-modal="false"
      :visible.sync="QrCodeVisible"
      @close="handleCloseQrcode"
      class="qrcode"
    >
      <div class="content">
        <div class="notes">分享二维码</div>
        <div class="imgBox">
          <div ref="qrcode_box"></div>
        </div>
        <!-- <el-button type="primary" @click="submitOrder">提交订单</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QrCode from 'qrcodejs2'
import DiskSidebar from 'components/disk/DiskSidebar.vue'
import DiskTableRow from 'components/disk/DiskTableRow.vue'
import DiskHeader from 'components/disk/DiskHeader.vue'
import DiskPath from 'components/disk/DiskPath.vue'
import DiskSearch from 'components/disk/DiskSearch.vue'
import UploadBigFile from 'components/disk/DiskUpload.vue'
import {
  getDiskList,
  sideBarType,
  searchList,
  getListType,
  addFiles,
  changeFilesName,
  fileDownload,
  delFiles,
} from 'api/disk'

export default {
  provide() {
    return {
      disk: this,
    }
  },
  data() {
    return {
      // 分享二维码弹窗
      QrCodeVisible: false,
      showDale: false,
      siderNum: {
        id: 0,
        name: '',
      },
      nubs: 1,
      nubs1: 1,
      checkData: [],
      moveid: '',
      fileList: [],
      type: 0,
      stry: 0,
      strys: 0,
      showDalget: true,
      MyItem: [{ id: '0', name: '我的智能网盘' }],
      MyItem1: [],
      uploadVisible: false,
      dialogVisible: false, //vip权益
      addFilesPopup: false, //新增文件夹弹窗

      vipList: [
        {
          title: 'VIP会员尊享权益',
          size: '20G',
          sizeDesc: '智能网盘',
          amount: '10000/月',
          amountDesc: '字符包',
          yearCard: '98',
          yearCardDesc: '仅￥8.17/月',
          classify: 1, //1是VIP 2是SVIP
        },
        {
          title: 'SVIP会员尊享权益',
          size: '50G',
          sizeDesc: '智能网盘',
          amount: '20000/月',
          amountDesc: '字符包',
          yearCard: '198',
          yearCardDesc: '仅￥16.50/月',
          classify: 2, //1是VIP 2是SVIP
        },
      ],
      parentId: null,
      conste: '',
      idx: 0,
      columnType: 1, //类型  网盘传1  版权传2  原创存证3 溯源4
      fileType: 0, //文件类型 0全部 word1  图片2 视频3 音频4
      form: {
        name: '',
      },
      checkData2: '',
      nub3: 0,
      addtitle: '新增文件夹',
    }
  },
  name: 'disk',
  components: {
    DiskSidebar,
    DiskTableRow,
    DiskHeader,
    DiskPath,
    DiskSearch,
    UploadBigFile,
  },

  created() {
    let id = this.$store.state.sidebarActive
    this.$nextTick(() => {
      if (id == 77) {
        this.getsideBarType({ name: '收藏文档', pageIndex: 1, pageSize: 11 })
      } else {
        this.getDiskLists({
          id: 0,
          pageIndex: 1,
          pageSize: 11,

          timeSort: 'desc',
        })
      }
    })
  },

  methods: {
    // 关闭去除二维码
    handleCloseQrcode() {
      // console.log(1);
      this.$refs.qrcode_box.innerHTML = ''
    },
    // 开通vip
    descBigBoxbtn() {
      this.$router.push({
        path: '/copyrightService/copyrightPracticeDetail/1',
      })
    },
    showType() {
      this.showDalget = !this.showDalget
      this.$refs.uploadFiles.addFiles1 = false
      if (this.nubs1 == 2) {
        this.$refs.uploadFiles.uploadFiles = false
        this.$refs.uploadFiles.addFiles = false
      } else {
        this.$refs.uploadFiles.uploadFiles = true
        this.$refs.uploadFiles.addFiles = true
      }
    },
    // 路径超出隐藏
    beyondHiding() {
      this.$nextTick(() => {
        this.$refs.diskPath.getdiskpath()
        if (this.$refs.diskPath.diskPath2 >= 580) {
          this.MyItem1.push(this.MyItem.shift())
        }
      })
    },
    // 回退
    Backoff() {
      // window.location.href =
      // "http://localhost:8083/ydregister?returnUrl=https://antiplagiarize.com&code=FBG9LX";
      if (this.MyItem.length != 1) {
        this.MyItem.pop()
        this.getDiskLists({
          id: this.MyItem[this.MyItem.length - 1].id,
          pageIndex: 1,
          pageSize: 11,

          timeSort: 'desc',
        })
      } else {
        this.$store.commit('ChangeSideBarId', 0)
        this.getDiskLists({
          id: 0,
          pageIndex: 1,
          pageSize: 11,

          timeSort: 'desc',
        })
      }
    },
    //上传文件
    uploadFiles() {
      this.uploadVisible = true
    },
    // 分享
    shareFlie1(url, faln, type) {
      if (type == 0) {
        this.$message({
          type: 'error',
          message: '文件夹不支持分享',
        })
      } else {
        this.QrCodeVisible = true
        this.$nextTick(() => {
          let fileName = faln.split('.')[0]
          fileName = fileName + '.溯源报告.pdf'
          this.crateQrcode(url, fileName)
        })
      }
    },
    // 下载
    downloadFied1() {
      if (this.checkData2.length <= 1) {
        if (this.checkData2[0].filetype == 0) {
          this.$message({
            type: 'error',
            message: '文件夹不支持下载',
          })
        } else {
          console.log(this.checkData2)
          let fileName = this.checkData2[0].filename
          let url = this.checkData2[0].url
          window.location.href =
            'https://fafs.antiplagiarize.com/' + url + '?attname=' + fileName
        }
      } else {
        this.$message({
          type: 'error',
          message: '暂不支持多个文件下载',
        })
      }
    },
    // 分享
    shareFlie() {
      if (this.checkData2.length <= 1) {
        if (this.checkData2[0].filetype == 0) {
          this.$message({
            type: 'error',
            message: '文件夹不支持分享',
          })
        } else {
          this.QrCodeVisible = true
          this.$nextTick(() => {
            let fileName = this.checkData2[0].filename.split('.')[0]
            fileName = fileName + '.溯源报告.pdf'
            let url = this.checkData2[0].url
            this.crateQrcode(url, fileName)
          })
        }
      } else {
        this.$message({
          type: 'error',
          message: '暂不支持多个文件分享',
        })
      }
    },
    // 生成二维码
    crateQrcode(pdf, fileName) {
      const params = {
        fileName: encodeURIComponent(fileName),
        url: encodeURIComponent(pdf),
      }
      this.qr = new QrCode(this.$refs.qrcode_box, {
        text: `http://cloud.antiplagiarize.com/share?fileName=${params.fileName}&url=${params.url}`,
        width: 128,
        height: 128,
        correctLevel: 3,
        colorDark: '#000000',
        colorLight: '#ffffff',
      })
    },
    // 删除文件
    async deletsFiles() {
      await delFiles(this.checkData).then((res) => {
        if (res.code == 200) {
          this.$refs.uploadFiles.addFiles1 = false
          this.$refs.uploadFiles.uploadFiles = true
          this.$refs.uploadFiles.addFiles = true
          this.$message({
            type: 'success',
            message: '删除成功',
          })
          if (this.siderNum.id == 0) {
            this.getDiskLists({
              id: this.MyItem[this.MyItem.length - 1].id,
              pageIndex: 1,
              pageSize: 11,

              timeSort: 'desc',
            })
          } else {
            let params = {
              name: this.siderNum.name,
              pageIndex: 1,
              pageSize: 11,
            }
            this.getsideBarType(params)
          }
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          })
        }
      })
    },
    // 清空所有文件
    removeFile() {
      this.$refs.diskTable.content = '确定要清空所有文件吗?'
      this.$refs.diskTable.showRealNameTips = true
      this.$refs.diskTable.sttry = 0
    },
    //新增文件夹
    addFiles() {
      if (this.$store.state.sidebarActive == 10) {
        this.$message({
          type: 'error',
          message: '回收站不可新建文件夹!',
        })
        return
      }
      this.addFilesPopup = true
    },
    // 双击
    refreshs() {
      this.$store.commit('ChangeSideBarId', 0)
      this.MyItem = [{ id: '0', name: '我的网盘' }]
      this.getDiskLists({
        id: 0,
        pageIndex: 1,
        pageSize: 11,

        timeSort: 'desc',
      })
    },
    // 刷新
    refresh() {
      console.log(this.siderNum)
      if (this.siderNum.id == 0) {
        this.getDiskLists({
          id: this.MyItem[this.MyItem.length - 1].id,
          pageIndex: 1,
          pageSize: 11,

          timeSort: 'desc',
        })
      } else {
        let params = {
          name: this.siderNum.name,
          pageIndex: 1,
          pageSize: 11,
        }
        this.getsideBarType(params)
      }
      // this.siderNum = {
      //   id: 0,
      //   name: ""
      // };
    },
    // 新增文件夹弹窗 关闭回调
    closeAddpopup() {
      this.form.name = ''
      this.addtitle = '新增文件夹'
    },

    handleSure() {
      if (this.addtitle == '新增文件夹') {
        this.addFilesSure()
      } else {
        this.hangleChangeName()
      }
    },
    // 新增文件夹确定
    async addFilesSure() {
      if (this.form.name == '') {
        this.$message({
          type: 'error',
          message: '文件夹名称不能为空',
        })
        return
      }
      const data = {
        filetype: 0,
        parentid: this.parentId,
        filename: this.form.name,
      }
      const res = await addFiles(data)
      let arr = this.MyItem
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '新增成功！',
        })
        this.addFilesPopup = false
        if (this.siderNum.id == 0) {
          this.getDiskLists({
            id: arr[arr.length - 1].id,
            pageIndex: 1,
            pageSize: 11,

            timeSort: 'desc',
          })
        } else {
          let params = {
            name: this.siderNum.name,
            pageIndex: 1,
            pageSize: 11,
          }
          this.getsideBarType(params)
        }
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },
    // 修改名称确定
    async hangleChangeName() {
      const data = {
        id: this.$refs.diskTable.rowItem.id,
        name: this.form.name,
      }
      const res = await changeFilesName(data)
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '修改成功！',
        })
        this.addFilesPopup = false
        // if (this.siderNum.id == 0) {
        this.getDiskLists({
          id: this.MyItem[this.MyItem.length - 1].id,
          pageIndex: 1,
          pageSize: 11,

          timeSort: 'desc',
        })
        // } else {
        //   this.getsideBarType({ name: this.siderNum.name });
        // }
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },
    // 移动
    moveTo() {
      this.$refs.diskTable.moveTo(this.checkData)
    },
    // 关闭上传文件弹出框时触发
    handlerClose() {
      if (this.siderNum.id == 0) {
        this.getDiskLists({
          id: this.MyItem[this.MyItem.length - 1].id,
          pageIndex: 1,
          pageSize: 11,

          timeSort: 'desc',
        })
      } else {
        let params = {
          name: this.siderNum.name,
          pageIndex: 1,
          pageSize: 11,
        }
        this.getsideBarType(params)
      }
      this.uploadVisible = false
    },
    // 文件获取
    async getDiskList(value) {
      let data = await getDiskList(value)
      data.data.records.forEach((item) => {
        item.showRightOp = false
        switch (item.filetype) {
          case 0:
            if (item.isDeleted == -1) {
              item.nub = '01'
            } else {
              item.nub = '03'
            }
            break
          case 1:
            item.nub = '04'
            break
          case 2:
            item.nub = '07'
            break
          case 3:
            item.nub = '08'
            break
          case 4:
            item.nub = '05'
            break
          case 5:
            item.nub = '06'
            break
          case 6:
            item.nub = '07'
            break
          case 7:
            item.nub = '04'
            break
          case 8:
            item.nub = '05'
            break
          case 9:
            item.nub = '14'
            break
          case 10:
            item.nub = '14'
            break
          case 11:
            item.nub = '09'
            break
          case 12:
            item.nub = '09'
            break
          case 13:
            item.nub = '09'
            break
          case 14:
            item.nub = '10'
            break
          case 15:
            item.nub = '01'
            break
          case 16:
            item.nub = '09'
            break
          case 17:
            item.nub = '12'
            break
          case 18:
            item.nub = '12'
            break
          case 19:
            item.nub = '13'
            break
          case 20:
            item.nub = '11'
            break
          case 21:
            item.nub = '15'
            break
          case 22:
            item.nub = '11'
            break
          case 23:
            item.nub = '15'
            break
        }
        this.fileList.push(item)
      })
      this.stry = data.data.records.length
      // this.strys = this.fileList.length;
    },
    // 文件获取
    async getDiskLists(value) {
      this.type = 0
      // this.strys = 0;
      if (this.nubs == 2) {
        this.$refs.uploadFiles.uploadFiles = true
        this.$refs.uploadFiles.addFiles = true
      }
      this.siderNum = {
        id: 0,
        name: '',
      }
      this.parentId = Number(value.id)
      let data = await getDiskList(value)
      data.data.records.forEach((item) => {
        item.showRightOp = false
        switch (item.filetype) {
          case 0:
            if (item.isDeleted == -1) {
              item.nub = '01'
            } else {
              item.nub = '03'
            }
            break
          case 1:
            item.nub = '04'
            break
          case 2:
            item.nub = '07'
            break
          case 3:
            item.nub = '08'
            break
          case 4:
            item.nub = '05'
            break
          case 5:
            item.nub = '06'
            break
          case 6:
            item.nub = '07'
            break
          case 7:
            item.nub = '04'
            break
          case 8:
            item.nub = '05'
            break
          case 9:
            item.nub = '14'
            break
          case 10:
            item.nub = '14'
            break
          case 11:
            item.nub = '09'
            break
          case 12:
            item.nub = '09'
            break
          case 13:
            item.nub = '09'
            break
          case 14:
            item.nub = '10'
            break
          case 15:
            item.nub = '01'
            break
          case 16:
            item.nub = '09'
            break
          case 17:
            item.nub = '12'
            break
          case 18:
            item.nub = '12'
            break
          case 19:
            item.nub = '13'
            break
          case 20:
            item.nub = '11'
            break
          case 21:
            item.nub = '15'
            break
          case 22:
            item.nub = '11'
            break
          case 23:
            item.nub = '15'
            break
        }
      })
      this.fileList = data.data.records
      this.stry = data.data.records.length
      this.strys = data.data.total
    },
    // 下载
    async downloadFied(url, name) {
      window.location.href =
        'https://fafs.antiplagiarize.com/' + url + '?attname=' + name
      // let data = await fileDownload(id);
      // let blob = new Blob([data], {
      //   type: "application/octet-stream"
      // });
      // // console.log(blob, "blob");
      // var link = document.createElement("a");
      // link.href = window.URL.createObjectURL(blob);
      // // console.log(link.href, "link.href======");
      // link.download = name;
      // link.click();
      // //释放内存
      // window.URL.revokeObjectURL(link.href);
    },
    handleSelection(arr) {
      if (this.nubs == 2 && arr != 1) {
        this.$refs.uploadFiles.uploadFiles = true
        this.$refs.uploadFiles.addFiles = true
        this.$refs.uploadFiles.addFiles1 = false
      } else {
        if (arr == 1) {
          this.$refs.uploadFiles.addFiles1 = true
          this.$refs.uploadFiles.uploadFiles = false
          this.$refs.uploadFiles.addFiles = false
        } else {
          this.$refs.uploadFiles.addFiles1 = false
          if (this.nubs1 == 2) {
            this.$refs.uploadFiles.uploadFiles = false
            this.$refs.uploadFiles.addFiles = false
          } else {
            this.$refs.uploadFiles.uploadFiles = true
            this.$refs.uploadFiles.addFiles = true
          }
        }
      }
    },
    async getsideBarTypes(str) {
      this.conste.pageIndex = str.pageIndex
      let arrr = await sideBarType(this.conste)
      if (arrr.code == 200) {
        arrr.data.records.forEach((item) => {
          switch (item.filetype) {
            case 0:
              if (item.isDeleted == -1) {
                item.nub = '01'
              } else {
                item.nub = '03'
              }
              break
            case 1:
              item.nub = '04'
              break
            case 2:
              item.nub = '07'
              break
            case 3:
              item.nub = '08'
              break
            case 4:
              item.nub = '05'
              break
            case 5:
              item.nub = '06'
              break
            case 6:
              item.nub = '07'
              break
            case 7:
              item.nub = '04'
              break
            case 8:
              item.nub = '05'
              break
            case 9:
              item.nub = '14'
              break
            case 10:
              item.nub = '14'
              break
            case 11:
              item.nub = '09'
              break
            case 12:
              item.nub = '09'
              break
            case 13:
              item.nub = '09'
              break
            case 14:
              item.nub = '10'
              break
            case 15:
              item.nub = '01'
              break
            case 16:
              item.nub = '09'
              break
            case 17:
              item.nub = '12'
              break
            case 18:
              item.nub = '12'
              break
            case 19:
              item.nub = '13'
              break
            case 20:
              item.nub = '11'
              break
            case 21:
              item.nub = '15'
              break
            case 22:
              item.nub = '11'
              break
            case 23:
              item.nub = '15'
              break
          }
          this.fileList.push(item)
        })
        this.stry = arrr.data.records.length
        // this.strys = this.fileList.length;
      }
    },
    async getsideBarType(str) {
      this.conste = str
      this.type = 1
      let arrr = await sideBarType(str)
      if (arrr.code == 200) {
        this.parentId = arrr.data.current
        arrr.data.records.forEach((item) => {
          switch (item.filetype) {
            case 0:
              if (item.isDeleted == -1) {
                item.nub = '01'
              } else {
                item.nub = '03'
              }
              break
            case 1:
              item.nub = '04'
              break
            case 2:
              item.nub = '07'
              break
            case 3:
              item.nub = '08'
              break
            case 4:
              item.nub = '05'
              break
            case 5:
              item.nub = '06'
              break
            case 6:
              item.nub = '07'
              break
            case 7:
              item.nub = '04'
              break
            case 8:
              item.nub = '05'
              break
            case 9:
              item.nub = '14'
              break
            case 10:
              item.nub = '14'
              break
            case 11:
              item.nub = '09'
              break
            case 12:
              item.nub = '09'
              break
            case 13:
              item.nub = '09'
              break
            case 14:
              item.nub = '10'
              break
            case 15:
              item.nub = '01'
              break
            case 16:
              item.nub = '09'
              break
            case 17:
              item.nub = '12'
              break
            case 18:
              item.nub = '12'
              break
            case 19:
              item.nub = '13'
              break
            case 20:
              item.nub = '11'
              break
            case 21:
              item.nub = '15'
              break
            case 22:
              item.nub = '11'
              break
            case 23:
              item.nub = '15'
              break
          }
        })
        this.fileList = arrr.data.records
        this.stry = arrr.data.records.length
      }
    },
    // 文件获取
    async getSideBarType(str) {
      this.siderNum = str
      if (str.name == '回收站') {
        this.$refs.uploadFiles.addFiles2 = true
      } else {
        this.$refs.uploadFiles.addFiles2 = false
      }
      if (
        str.name == '存证' ||
        str.name == '授权' ||
        str.name == '溯源报告' ||
        str.name == '云文档'
      ) {
        this.$refs.uploadFiles.uploadFiles = true
        this.$refs.uploadFiles.addFiles = true
      } else {
        this.$refs.uploadFiles.uploadFiles = false
        this.$refs.uploadFiles.addFiles = false
      }

      this.nubs = 1
      let params = {
        name: this.siderNum.name,
        pageIndex: 1,
        pageSize: 11,
      }
      this.getsideBarType(params)
    },
    // 文件搜索
    async handleSearch(value) {
      let data = await searchList({ name: value })
      if (data.code == 200) {
        data.data.records.forEach((item) => {
          switch (item.filetype) {
            case 0:
              if (item.isDeleted == -1) {
                item.nub = '01'
              } else {
                item.nub = '03'
              }
              break
            case 1:
              item.nub = '04'
              break
            case 2:
              item.nub = '07'
              break
            case 3:
              item.nub = '08'
              break
            case 4:
              item.nub = '05'
              break
            case 5:
              item.nub = '06'
              break
            case 6:
              item.nub = '07'
              break
            case 7:
              item.nub = '04'
              break
            case 8:
              item.nub = '05'
              break
            case 9:
              item.nub = '14'
              break
            case 10:
              item.nub = '14'
              break
            case 11:
              item.nub = '09'
              break
            case 12:
              item.nub = '09'
              break
            case 13:
              item.nub = '09'
              break
            case 14:
              item.nub = '10'
              break
            case 15:
              item.nub = '01'
              break
            case 16:
              item.nub = '09'
              break
            case 17:
              item.nub = '12'
              break
            case 18:
              item.nub = '12'
              break
            case 19:
              item.nub = '13'
              break
            case 20:
              item.nub = '11'
              break
            case 21:
              item.nub = '15'
              break
            case 22:
              item.nub = '11'
              break
            case 23:
              item.nub = '15'
              break
          }
        })
        this.fileList = data.data.records
      }
      setTimeout(() => {
        this.$refs.valueSearch.value = ''
      }, 500)
    },
    // 类型搜索
    async handleSearchForType(value) {
      let data = await getListType({
        type: value,
        pageIndex: 1,

        timeSort: 'desc',
      })
      this.fileList = data.data
    },
  },
}
</script>

<style lang="scss" scoped>
.disk {
  display: flex;
  height: calc(100vh - 110px);
  border: 1px solid #eceff1;

  user-select: none;
  .dik-nav {
    @include flex-between;
    height: 50px;
    border-bottom: 1px solid #eef0f4;
    .disk-operate {
      @include flex-between;
      width: 108px;
      padding: 0 20px;
      height: 28px;
      border-right: 1px solid #eceff1;
      box-sizing: border-box;
      .disk-operate-prev {
        cursor: pointer;
        display: inline-block;
        width: 20px;
        height: 32px;
        margin-top: 30%;
        @include backgroundGroup('~assets/images/disk/disk-operate-prev.png');
        background-size: 7px 12px;
        &:active {
          transform: scale(1.3);
        }
      }
      .disk-operate-refresh {
        cursor: pointer;
        display: inline-block;
        width: 19px;
        height: 14px;
        @include backgroundGroup(
          '~assets/images/disk/disk-operate-refresh.png'
        );
        transition: all 0.1s ease-out;
        &:active {
          transform: rotate(20deg);
        }
      }
    }
    .disk-path {
      @include flex-start;
      padding: 0 20px;
      flex: 1;
      height: 28px;
    }
    .show-type {
      cursor: pointer;
      margin-right: 20px;
      display: inline-block;
      width: 15px;
      height: 16px;
      @include backgroundGroup('~assets/images/disk/show-type.png');
    }
  }
  .disk-content {
    flex: 1;
  }
  .interest {
    ::v-deep .el-dialog {
      width: 513px;
      height: 450px;
      background: #ffffff;
      border: 1px solid #e8eaef;
      box-shadow: 0px 3px 16px 0px rgba(129, 135, 144, 0.22);
      border-radius: 12px;
      .el-dialog__close {
        font-size: 20px;
      }
      .el-dialog__body {
        padding: 30px 40px;
        .content {
          .content-item {
            margin-top: 40px;
            .content-item-title {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
              .content-item-line {
                width: 4px;
                height: 17px;
                background: #f5d693;
                box-shadow: 2px 0px 0px 0px #fff6d3;
                margin-right: 7px;
              }
            }
            .content-desc {
              margin-top: 20px;
              display: flex;
              .desc-item {
                width: 107px;
                height: 122px;
                background: #ffffff;
                box-shadow: 0px 4px 16px 0px rgba(188, 188, 188, 0.3);
                border-radius: 6px;
                margin-right: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                .desc-item-content {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .desc-item-content-icon {
                    width: 28px;
                    height: 26px;
                  }
                  .drillIcon {
                    @include backgroundGroup('~assets/images/drillIcon.png');
                  }
                  .textIcon {
                    @include backgroundGroup('~assets/images/textIcon.png');
                  }
                  .desc-item-content-title {
                    margin-top: 12px;
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #222222;
                  }
                  .desc-item-content-story {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    margin-top: 10px;
                  }
                }
              }
              .desc-bigBox {
                width: 154px;
                height: 122px;
                margin-left: 6px;
                text-align: center;
                .desc-bigBox-title {
                  margin-top: 20px;
                  font-size: 18px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #222222;
                  span {
                    color: #ea2020;
                  }
                }
                .desc-bigBox-story {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #999999;
                  margin-top: 5px;
                }
                .desc-bigBox-btn {
                  cursor: pointer;
                  width: 153px;
                  height: 39px;
                  border-image: linear-gradient(0deg, #f9d7a8, #feedce) 10 10;
                  background: linear-gradient(90deg, #f9d7a9 0%, #fdeac9 100%);
                  border-radius: 19px;
                  line-height: 39px;
                  margin-top: 15px;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #2d2e31;
                }
              }
            }
          }
          .content-item:first-of-type {
            margin-top: 0px;
          }
        }
      }
    }
  }
  .addFiles {
    ::v-deep .el-dialog {
      width: 532px;
      .el-form-item__label {
        text-align: center;
      }
      .el-input {
        width: 90%;
      }
    }
  }
  // 文件上传
  .upload {
    ::v-deep .el-dialog {
      width: 45%;
      min-height: 260px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;
        .el-dialog__title {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 15px;
        }
      }
      .el-dialog__body {
        padding: 0px 30px;
        .content-text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
      }
    }
  }
  .qrcode {
    ::v-deep .el-dialog {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        padding: 65px 20px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }
          .imgBox {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>
<style>
.showDale {
  width: 100%;
  height: 15px;
  font-size: 14px;
  color: #696969;
  padding: 15px 0 0 10px;
}
</style>
